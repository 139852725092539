import {
  type Auth,
  EmailAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCredential,
  type User,
  type UserCredential,
  signOut as firebaseSignOut,
  type Unsubscribe,
} from "firebase/auth";
import { setAuthGuest, setAuthLoading, setAuthUser } from "../auth.store";
import type { AuthServiceMethodsImplementation, AuthStore, AuthStoreUser, SetAuthStoreFunc } from "../auth.types";
import { isGuestEmail, prepareSyncAuthWithAPIParams, createTokenRefresher } from "../auth.helpers";
import { codes, getRequestClient } from "@repo/client";
import { onCleanup, type Accessor } from "solid-js";
import { type Logger, Named } from "@repo/logger";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../firebaseConfig";
import { BrowserStorage } from "@repo/storage";
import { stAnalytics } from "@repo/analytics";
import { isServer } from "solid-js/web";
import { useAuthNoOpMethods } from "./authNoOpMethods";

/**
 * Implmentation for the auth methods that are used in the chrome extension.
 *
 * Unlike in the browser that will use its own firebase instance, the extension does all the
 * auth-related firebase logic in the background (see background.ts in `apps/extension/entrypoints/background.ts`)
 *
 * Due to this, we have to interact with it through 2-way messaging using the chrome/browser runtime message passing
 */
export const useAuthBrowserMethods: AuthServiceMethodsImplementation = (deps: {
  logger: Logger;
  store: AuthStore;
  setStore: SetAuthStoreFunc;
  deviceId: Accessor<string>;
  locale: Accessor<string>;
  userAgent: Accessor<string>;
}) => {
  /**
   * Auth on the server is not possible for now, so we return a no-op implementation
   */
  if (isServer) return useAuthNoOpMethods(deps);

  const logger = new Named(deps.logger, "authBrowser");
  const { store, setStore, deviceId, locale, userAgent } = deps;
  const setToken = (token: string) => setStore("token", token);

  const firebaseInstance = initializeApp(firebaseConfig());
  const auth = getAuth(firebaseInstance);
  const refresher = createTokenRefresher(auth, (token: string) => setToken(token), logger);
  refresher.schedule(5 * 60);

  const syncAuthWithAPI = async (firebaseUser: User): Promise<{ type: "user" | "guest"; user: AuthStoreUser }> => {
    logger.info("syncAuthWithAPI", { firebaseUser });

    const params = prepareSyncAuthWithAPIParams(firebaseUser, deviceId(), locale(), userAgent());
    const client = getRequestClient(() => store.token);
    const result = await client.controlplane.AuthSync(params);

    if (result.code !== codes.OK) {
      logger.warn("auth sync failed", { result });
    } else {
      logger.info("auth sync complete", { result });
    }

    if (result.code === codes.OK && result.data.tokenRefreshRequired) {
      logger.info("api has set new claims -- will refresh firebase token");
      setToken(await firebaseUser.getIdToken(true));
      logger.info("refreshed token");
    }

    const user = result.data.user;
    const wCtx = result.data.workingContext;

    const userResult = {
      type: isGuestEmail(user.email) ? "guest" : ("user" as "user" | "guest"),
      user: {
        userId: user.id,
        displayName: user.displayName,
        email: user.email,
        emailVerified: params.isEmailVerified, // todo: fix API should be returning this.
        pictureURL: user.pictureURL,
        didSignUp: result.data.didSignUp,
        didClaimInvites: result.data.didClaimInvites,
        potentialScore: user.potentialScore,
        workingContext: {
          organizationId: wCtx.organizationId,
          tenantId: wCtx.tenantId,
          collectionTree: wCtx.CollectionTree ?? [],
          operationId: result.OperationID,
        },
      },
    };

    logger.info("syncAuthWithAPI result", userResult);

    return userResult;
  };

  const signInWithMagicLink = async (email: string, invite?: { type: "direct" | "link"; token: string }) => {
    const childLogger = logger.child("signInWithMagicLink");
    if (_LOG) childLogger.info("starting");
    auth.languageCode = deps.locale();
    await sendSignInLinkToEmail(auth, email, {
      url: `https://${APP_DOMAIN}/auth/finish${invite ? `?${invite.type === "direct" ? "invite" : "collectionLink"}=${invite.token}` : ""}`,
      handleCodeInApp: true,
    }).then(() => {
      BrowserStorage.setLastUsedMagicLinkEmail(email);
    });
  };

  const finishSignInMagicLink = async (email: string, url: string) => {
    const childLogger = logger.child("finishSignInMagicLink");
    if (_LOG) childLogger.info("starting");

    setAuthLoading(setStore);

    if (!auth.currentUser) {
      if (_LOG) childLogger.info("No current user, aborting");
      return;
    }

    if (!auth.currentUser.isAnonymous) {
      if (_LOG) childLogger.info("The user isn't logged out / anonymous, aborting");
      return;
    }

    const emailCredential = EmailAuthProvider.credentialWithLink(email, url);

    let credential: UserCredential;

    let didSignUp = false;
    try {
      credential = await linkWithCredential(auth.currentUser, emailCredential);
      didSignUp = true;
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        credential = await signInWithCredential(auth, emailCredential);
        didSignUp = false;
      } else {
        throw error;
      }
    }

    BrowserStorage.removeLastUsedMagicLinkEmail();

    if (!emailCredential || !credential) {
      if (_LOG) childLogger.warn("credentials not found within result, aborting");
      return;
    }

    // establish the initial identity token
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      return;
    }
    setToken(token);

    const identity = await syncAuthWithAPI(credential.user);
    childLogger.info("response from syncAuthWithAPI", { identity });

    try {
      await stAnalytics.track(didSignUp ? "sign_up_successful" : "log_in_successful", {
        email: identity.user.email,
        userId: identity.user.userId,
      });
      if (didSignUp) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (window as any).dataLayer?.push("event", "conversion_event_signup", {
          email: identity.user.email,
          userId: identity.user.userId,
        });
      }
    } catch (error) {}

    if (identity.type === "user") {
      setAuthUser(setStore, identity.user);
    } else {
      setAuthGuest(setStore, identity.user);
    }
  };

  const isURLMagicLink = (url: string) => isSignInWithEmailLink(auth, url);

  const signInWithGoogle = async () => {
    const childLogger = logger.child("signInWithOAuth");
    if (_LOG) childLogger.info("starting");
    if (!auth.currentUser) {
      if (_LOG) childLogger.info("Current user doesn't exist, aborting");
      return;
    }

    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    auth.languageCode = deps.locale();
    await linkWithRedirect(auth.currentUser, provider);
  };

  const processOAuthRedirect = async () => {
    const childLogger = logger.child("processOAuthRedirect");
    if (_LOG) childLogger.info("starting");

    let result: UserCredential | null = null;

    let didSignUp = false;
    try {
      result = await getRedirectResult(auth);
      didSignUp = true;
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      // INFO: Handle multiple errors here when we add providers other than google
      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#getredirectresult
      if (error.code === "auth/credential-already-in-use") {
        childLogger.info("credential already in use", { error });
        // The docs are wrong about this, there's no error.credential, but there's an error.customData
        const googleCredential = GoogleAuthProvider.credential(
          error.customData._tokenResponse.oauthIdToken,
          error.customData._tokenResponse.oauthAccessToken,
        );
        result = await signInWithCredential(auth, googleCredential);
        didSignUp = false;
      } else {
        throw error;
      }
    }
    if (!result) {
      if (_LOG) childLogger.info("redirectResult is null, aborting");
      return;
    }

    logger.info("OAuthRedirect obtained result", { result });

    const credential = GoogleAuthProvider.credentialFromResult(result);

    if (!credential) {
      if (_LOG) childLogger.warn("credentials not found within result, aborting");
      return;
    }

    // establish the initial identity token
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      // if (_LOG) childLogger.warn("token not found within result, aborting");
      return;
    }
    setToken(token);

    await stAnalytics.track(didSignUp ? "sign_up_successful" : "log_in_successful", {
      email: result.user.email,
      userId: result.user.uid,
    });

    const identity = await syncAuthWithAPI(result.user);
    childLogger.info("response from syncAuthWithAPI", { identity });

    try {
      if (didSignUp) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (window as any).dataLayer?.push("event", "conversion_event_signup", {
          email: identity.user.email,
          userId: identity.user.userId,
        });
      }
    } catch (error) {}

    if (identity.type === "user") {
      setAuthUser(setStore, identity.user);
    } else {
      setAuthGuest(setStore, identity.user);
    }

    return true;
  };

  const onUnauthenticated = async () => {
    logger.info("signOut");
    setAuthLoading(setStore);
    await signInAnonymously(auth);
  };

  const setupListeners = async () => {
    if (isServer) return;

    let didProcessOAuthRedirect = await processOAuthRedirect();

    let lastUserId: string | null = null;

    onAuthStateChanged(auth, async (user: User | null) => {
      const childLogger = logger.child("onAuthStateChanged");
      if (!user) {
        if (_LOG) childLogger.info("no user; signing out");
        await onUnauthenticated();
        return;
      }

      if (_LOG) childLogger.info("user detected", { user });
      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        if (_LOG) childLogger.warn("token not found within result, aborting");
        // log exception here
        return;
      }
      setToken(token);

      if (user.uid === lastUserId) {
        return;
      }

      lastUserId = user.uid;

      if (didProcessOAuthRedirect) {
        didProcessOAuthRedirect = false;
        return;
      }

      const identity = await syncAuthWithAPI(user);

      if (identity.type === "user") {
        setAuthUser(setStore, identity.user);
      } else {
        setAuthGuest(setStore, identity.user);
      }
    });
  };

  const signOut = () => {
    return firebaseSignOut(auth).then((r) => {
      stAnalytics.plugins.posthog.reset();
      return r;
    });
  };

  return {
    signInWithMagicLink,
    signOut,
    setupListeners,
    signInWithGoogle,
    processOAuthRedirect,
    finishSignInMagicLink,
    isURLMagicLink,
  };
};
