// @refresh reload
import { StartClient, mount } from "@solidjs/start/client";
import { COLOR_THEME_MANAGER } from "@core/lib/theme";

const getRoot = () => {
  const root = document.getElementById("app");

  // Listen for color scheme changes outside of SolidJS
  COLOR_THEME_MANAGER.listen();

  if (!root) {
    // TODO: Observe Error
    throw new Error("Root element not found");
  }
  return root;
};

mount(() => <StartClient />, getRoot());

export default null;
