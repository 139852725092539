import { Navigate, useLocation } from "@solidjs/router";
import { Match, type ParentComponent, Show, Switch, children, lazy, onMount } from "solid-js";
import { NewHomePage } from "@core/marketing/screens/NewHomePage";
import {
  type CampaignPageData,
  type CampaingPageDataLevel,
  type CampaingPageDataPrompt,
  useUseCasesData,
} from "@core/marketing/useUseCasesData";
import { useWire } from "@core/wire";

const HomeScreen = lazy(() =>
  import("@core/marketing/screens/HomeScreen").then((r) => ({
    default: r.HomeScreen,
  })),
);
const MarketingLayout = lazy(() =>
  import("@core/components/_original/MarketingLayout").then((r) => ({
    default: r.MarketingLayout,
  })),
);
const ThreadScreen = lazy(() =>
  import("@core/domains/threads/screens/ThreadScreen").then((r) => ({
    default: r.ThreadScreen,
  })),
);

export const Layout: ParentComponent = (props) => {
  const location = useLocation();
  const { data } = useUseCasesData(() => location.pathname);
  const wire = useWire();

  const ch = children(() => props.children);

  return (
    <>
      <Switch>
        <Match when={data() && "redirect" in data()!}>
          <Navigate href={(data() as { redirect: string }).redirect} />
        </Match>
        <Match
          when={
            (data() as CampaignPageData)?.type === "level" && (data() as CampaingPageDataLevel)?.level?.type === "l0"
          }
        >
          <MarketingLayout>
            <NewHomePage data={data() as CampaingPageDataLevel} />
          </MarketingLayout>
        </Match>
        <Match
          when={
            (data() as CampaignPageData)?.type === "level" && (data() as CampaingPageDataLevel)?.level?.type !== "l0"
          }
        >
          <MarketingLayout>
            <HomeScreen data={data() as CampaingPageDataLevel} />
          </MarketingLayout>
        </Match>
        <Match when={(data() as CampaignPageData)?.type === "prompt"}>
          <MarketingLayout>
            <ThreadScreen data={[]} location={location} params={{}} prompt={data() as CampaingPageDataPrompt} />
          </MarketingLayout>
        </Match>
        <Match when={location.pathname.startsWith("/project")}>
          <Show when={!wire.metadata.isDefault}>{ch()}</Show>
        </Match>
        <Match when={true}>
          <MarketingLayout>{ch()}</MarketingLayout>
        </Match>
      </Switch>
    </>
  );
};
